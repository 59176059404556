import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

const CaptureEventModal = ({
  show,
  onHide,
  sessionCount,
  handleSessionCountChange,
  handleCapture,
  loading,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Get Event Data from ERS System</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId='formSessionCount'>
            <Form.Label>Session</Form.Label>
            <Form.Control
              type='number'
              min='1'
              value={sessionCount}
              onChange={handleSessionCountChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={handleCapture} disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </Button>
        <Button
          variant='secondary'
          onClick={onHide}
          style={{ marginLeft: '20px' }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CaptureEventModal
