import React from 'react'
import { useAuth } from '../../context/auth'
import { Link, useNavigate } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

const UserHeader = () => {
  const [auth, setAuth] = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: '',
    })
    localStorage.removeItem('auth')

    navigate('/login', { state: { loggedOut: true } })
  }

  return (
    <Navbar
      collapseOnSelect
      expand='lg'
      variant='dark'
      fixed='top'
      style={{ backgroundColor: '#7a6844' }}
    >
      <Container>
        <Navbar.Brand href='/admin/dashboard'>ERS</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            {auth?.user && (
              <Link
                className='navbar-brand'
                style={{ color: 'white', textDecoration: 'none' }}
                to='/user/dashboard'
              >
                Dashboard
              </Link>
            )}
          </Nav>
          <Nav className='ms-auto align-items-center'>
            {auth?.user && (
              <span
                className='navbar-text'
                style={{ marginRight: '20px', color: 'white' }}
              >
                User: {auth?.user?.name}
              </span>
            )}
            {auth?.user ? (
              <Nav.Link onClick={handleLogout} style={{ color: 'white' }}>
                Logout
              </Nav.Link>
            ) : (
              <Nav.Link href='/login' style={{ color: 'white' }}>
                Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default UserHeader
