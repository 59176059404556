import { Routes, Route } from 'react-router-dom'
import Login from './pages/Auth/Login'
import PageNotFound from './pages/PageNotFound'
import PrivateRoute from './components/Routes/PrivateRoute'
import HomePage from './pages/HomePage'
import AdminRoute from './components/Routes/AdminRoute'
import AdminDashboard from './pages/Admin/Dashboard'
import AdminEventRegisterList from './pages/Admin/Events/EventRegisterLists'
import UserRoute from './components/Routes/UserRoute'
import UserDashboard from './pages/User/Dashboard'
import AdminUsersList from './pages/Admin/User/UsersList'
import AdminCreateUser from './pages/Admin/User/CreateUser'
import AdminEditUser from './pages/Admin/User/EditUser'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<HomePage />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<PageNotFound />} />

        {/* Admin */}
        <Route path='/admin' element={<AdminRoute />}>
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route
            path='event/:eventId/:id'
            element={<AdminEventRegisterList />}
          />
          <Route path='users' element={<AdminUsersList />} />
          <Route path='users/new' element={<AdminCreateUser />} />
          <Route path='users/:id' element={<AdminEditUser />} />
          <Route path='check-in' element={<UserDashboard />} />
        </Route>

        {/* User */}
        <Route path='/user' element={<UserRoute />}>
          <Route path='dashboard' element={<UserDashboard />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
