import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../context/auth'

const UserDashboard = () => {
  const [auth] = useAuth()
  const userId = auth?.user?._id

  const [qrCode, setQrCode] = useState('')
  const [userData, setUserData] = useState(null)
  const [userList, setUserList] = useState([])
  const [eventTitle, setEventTitle] = useState('')
  const [session, setSession] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const qrInputRef = useRef(null)
  const searchInputRef = useRef(null)
  const errorSound = new Audio('/error-sound.mp3')

  useEffect(() => {
    const fetchEventTitle = async () => {
      try {
        const response = await axios.get(
          '/api/v1/user/attendant/latest-event-title'
        )
        setEventTitle(response.data.title || 'Event')
        setSession(response.data.session || null)
      } catch (error) {
        console.error('Failed to fetch event title:', error)
      }
    }

    fetchEventTitle()
  }, [])

  useEffect(() => {
    if (searchTerm.length > 3) {
      handleSearch()
    }
  }, [searchTerm])

  const handleInputChange = (e) => {
    setQrCode(e.target.value)
  }

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleQrCodeCheckIn = async () => {
    try {
      const response = await axios.post(
        '/api/v1/user/attendant/qrcode-checkin',
        { qrCode, userId }
      )
      setUserData(response.data.user)
      setUserList([]) // Clear any previous search results
      toast.success(response.data.message || 'Registered Successfully!')
    } catch (err) {
      if (err.response.status === 409) {
        toast.error(err.response.data.message)
        setUserData(err.response.data.user)
        setUserList([])
        errorSound.play()
      } else {
        setUserData(null)
        setUserList([])
        toast.error('No Record Found!')
      }
    } finally {
      setQrCode('')
      qrInputRef.current.focus()
    }
  }

  const handleSearch = async () => {
    try {
      const response = await axios.post('/api/v1/user/attendant/search-user', {
        searchTerm,
      })
      setUserList(response.data.users)
      setUserData(null) // Clear any previous QR code result
    } catch (err) {
      setUserList([])
      setUserData(null)
    } finally {
      searchInputRef.current.focus()
    }
  }

  const handleClear = () => {
    setQrCode('')
    setSearchTerm('')
    setUserData(null)
    setUserList([])
    qrInputRef.current.focus()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (qrCode) {
        handleQrCodeCheckIn()
      }
    }
  }

  const handleSearchUserCheckInClick = async (eventUserId) => {
    try {
      const response = await axios.post(
        '/api/v1/user/attendant/search-user-check-in',
        {
          userId,
          eventUserId,
        }
      )

      setUserList((prevList) =>
        prevList.map((user) =>
          user._id === eventUserId
            ? { ...user, checkin: response.data.user.checkin, status: 1 }
            : user
        )
      )
      setSearchTerm('')
      qrInputRef.current.focus()
    } catch (err) {
      toast.error('Failed to check in user.')
    }
  }

  return (
    <Layout title={'User Dashboard'}>
      <div className='shadow-sm p-3 bg-white rounded' style={{ width: '100%' }}>
        <div className='fw-bold fs-5 mt-1 mb-3' style={{ color: '#4d422b' }}>
          {session ? `[Session ${session}] ` : ''}
          {eventTitle}
        </div>
        <div className='col-auto' style={{ display: 'flex', gap: '80px' }}>
          <div style={{ flex: 1, display: 'flex' }}>
            <input
              type='text'
              className='form-control'
              placeholder='Scan QR Code'
              value={qrCode}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoFocus
              ref={qrInputRef}
            />
            <button
              type='button'
              className='btn btn-outline-primary'
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
          <div style={{ flex: 1, display: 'flex' }}>
            <input
              type='text'
              className='form-control'
              placeholder='Search ID/Name/Phone'
              value={searchTerm}
              onChange={handleSearchTermChange}
              ref={searchInputRef}
            />
            <button
              type='button'
              className='btn btn-outline-success'
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </div>
        {userData && (
          <table className='table mt-3'>
            <thead>
              <tr>
                <th scope='col'>ID</th>
                <th scope='col'>Name</th>
                <th scope='col'>Chi. Name</th>
                <th scope='col'>Phone</th>
                <th scope='col'>Locality</th>
                <th scope='col'>Language</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{userData.eventRegisterId}</td>
                <td>{userData.name}</td>
                <td>{userData.chineseName}</td>
                <td>{userData.phone}</td>
                <td>{userData.locality}</td>
                <td>{userData.language}</td>
                <td className='text-end'>
                  {userData.checkin
                    ? moment.utc(userData.checkin).format('YYYY-MM-DD HH:mm:ss')
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {userList.length > 0 && (
          <table className='table mt-3'>
            <thead>
              <tr>
                <th scope='col'>ID</th>
                <th scope='col'>Name</th>
                <th scope='col'>Chi. Name</th>
                <th scope='col'>Phone</th>
                <th scope='col'>Locality</th>
                <th scope='col'>Language</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userList.map((user) => (
                <tr key={user._id}>
                  <td>{user.eventRegisterId}</td>
                  <td>{user.name}</td>
                  <td>{user.chineseName}</td>
                  <td>{user.phone}</td>
                  <td>{user.locality}</td>
                  <td>{user.language}</td>
                  <td className='text-end'>
                    {user.checkin ? (
                      moment.utc(user.checkin).format('YYYY-MM-DD HH:mm:ss')
                    ) : (
                      <button
                        type='button'
                        className='btn btn-success btn-sm'
                        onClick={() => handleSearchUserCheckInClick(user._id)}
                      >
                        Check in
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Layout>
  )
}

export default UserDashboard
