import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout/Layout'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AdminUsersList = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])

  const getAllUsers = async () => {
    try {
      const { data } = await axios.get('/api/v1/admin/user/all-users')
      if (data?.success) {
        setUsers(data?.users)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  return (
    <Layout title={'Users'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <button
            type='button'
            class='btn btn-outline-primary btn-sm'
            onClick={() => navigate('/admin/users/new')}
          >
            Add New User
          </button>
          <button
            type='button'
            class='btn btn-outline-secondary btn-sm ms-3'
            onClick={() => navigate('/admin/dashboard')}
          >
            Return
          </button>
        </div>
        <table class='table'>
          <thead>
            <tr>
              <th scope='col'>No.</th>
              <th scope='col'>Name</th>
              <th scope='col'>Username</th>
              <th>Role</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <>
                <tr>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.username}</td>
                  <td>{user.role === 1 ? 'Admin' : 'User'}</td>
                  <td>
                    {user.status === 1 ? (
                      <span style={{ color: 'green' }}>Active</span>
                    ) : (
                      <span style={{ color: 'grey' }}>Inactive</span>
                    )}
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn btn-outline-primary btn-sm border-0 ms-2'
                      onClick={() => {
                        navigate(`/admin/users/${user._id}`)
                      }}
                    >
                      <i class='bi bi-pencil-square'></i>
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default AdminUsersList
