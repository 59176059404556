import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import CaptureEventModal from './Events/CaptureEventModal'
import { Button, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AdminDashboard = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [sessionCount, setSessionCount] = useState(1)
  const [statLoadingRow, setStatLoadingRow] = useState(new Set())

  const retrieveEventData = async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await axios.get('/api/v1/admin/event/get-data')
      setEvents(response.data)
    } catch (error) {
      toast.error('Failed to fetch event data')
      setError('Failed to fetch event data')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    retrieveEventData()
  }, [])

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleSessionCountChange = (e) => {
    setSessionCount(e.target.value)
  }

  const fetchEventData = async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await axios.post('/api/v1/admin/event/capture-data', {
        sessionCount: sessionCount,
      })
      setEvents(response.data)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // Display the specific error message from the backend
        toast.error(error.response.data.error)
      } else {
        // Handle generic error
        toast.error('Failed to fetch event data')
      }
      setError('Failed to fetch event data')
    } finally {
      setLoading(false)
      handleCloseModal()
    }
  }

  const formatDate = (date) => {
    const d = new Date(date)
    const year = d.getFullYear()
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const day = String(d.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handleStatusClick = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1
    const confirmChange = window.confirm('Confirm update status?')

    if (confirmChange) {
      try {
        const response = await axios.post(
          '/api/v1/admin/event/update-event-status',
          {
            id,
            status: newStatus,
          }
        )

        const updatedEvent = response.data

        // Update the state with the new status
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event._id === id ? { ...event, status: updatedEvent.status } : event
          )
        )

        toast.success('Status updated successfully!')
      } catch (error) {
        console.error('Failed to update event status:', error)
        toast.error('Failed to update event status')
      }
    }
  }

  const deleteData = async (itemId) => {
    try {
      if (window.confirm('Confirm Delete?')) {
        await axios.delete(`/api/v1/admin/event/event-data/${itemId}`)
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event._id !== itemId)
        )
        toast.success('Event deleted successfully!')
      }
    } catch (error) {
      console.error('Failed to delete item:', error)
    }
  }

  const exportStatistics = async (eventId, eventCode, eventSession) => {
    try {
      setStatLoadingRow((prevLoadingRows) =>
        new Set(prevLoadingRows).add(eventId)
      )

      const response = await axios.get(
        `/api/v1/admin/event/export-statistics/${eventId}`,
        { responseType: 'blob' } // Important for handling file download
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${eventCode}_${eventSession}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Failed to export statistics:', error)
      toast.error('Failed to export statistics')
    } finally {
      setStatLoadingRow((prevLoadingRows) => {
        const updatedRows = new Set(prevLoadingRows)
        updatedRows.delete(eventId)
        return updatedRows
      })
    }
  }
  return (
    <Layout title={'Admin Dashboard'}>
      <div className='pt-3 mx-3'>
        <div className='text-end mb-3'>
          <Button
            variant='outline-primary'
            size='sm'
            style={{ marginRight: '20px' }}
            onClick={handleShowModal}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Get Event Data from ERS System'}
          </Button>
          <Link to={'/admin/users'}>
            <button
              type='button'
              className='btn btn-outline-info btn-sm'
              style={{ marginRight: '20px' }}
            >
              Users
            </button>
          </Link>
          <Link to={'/admin/check-in'}>
            <button type='button' className='btn btn-outline-success btn-sm'>
              Check In Page
            </button>
          </Link>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>ID</th>
              <th scope='col'>Event Name</th>
              <th scope='col'>Date</th>
              <th scope='col'>Participants</th>
              <th scope='col'>Status</th>
              <th>Statistic</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event._id}>
                <td>{event.eventId}</td>
                <td>
                  {event.moreSession && ` [Session ${event.session}]`}
                  <span style={{ marginLeft: '10px' }}>{event.name}</span>
                </td>
                <td>
                  {event.startDate === event.endDate ? (
                    formatDate(event.startDate)
                  ) : (
                    <>
                      {formatDate(event.startDate)} ~{' '}
                      {formatDate(event.endDate)}
                    </>
                  )}
                </td>
                <td>{event.participantCount}</td>
                <td
                  onClick={() => handleStatusClick(event._id, event.status)}
                  style={{ cursor: 'pointer' }}
                >
                  {event.status === 1 ? (
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      ON
                    </span>
                  ) : (
                    <span style={{ color: 'grey', fontWeight: 'bold' }}>
                      OFF
                    </span>
                  )}
                </td>
                <td>
                  {statLoadingRow.has(event._id) ? (
                    <Spinner
                      animation='border'
                      size='sm'
                      role='status'
                      style={{ color: '#007bff' }}
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  ) : (
                    <i
                      className='bi bi-bar-chart-line-fill'
                      style={{ cursor: 'pointer', color: '#007bff' }}
                      onClick={() =>
                        exportStatistics(event._id, event.code, event.session)
                      }
                    ></i>
                  )}
                </td>
                <td className='text-end'>
                  <Link to={`/admin/event/${event.eventId}/${event._id}`}>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-sm'
                    >
                      View
                    </button>
                  </Link>
                  <span
                    className='ms-3'
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteData(event._id)}
                  >
                    <i
                      className='bi bi-trash3'
                      style={{ color: '#dc3545' }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <CaptureEventModal
          show={showModal}
          onHide={handleCloseModal}
          sessionCount={sessionCount}
          handleSessionCountChange={handleSessionCountChange}
          handleCapture={fetchEventData}
          loading={loading}
        />
      </div>
    </Layout>
  )
}

export default AdminDashboard
