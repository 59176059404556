import React from 'react'
import AdminHeader from './AdminHeader'
import LoginHeader from './LoginHeader'
import { useAuth } from '../../context/auth'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserHeader from './UserHeader'

const Layout = ({ children, title, description, author }) => {
  const [auth] = useAuth()

  const renderHeader = () => {
    if (!auth?.user) return <LoginHeader />

    return auth.user.role === 1 ? <AdminHeader /> : <UserHeader />
  }

  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta name='author' content={author} />
        <title>{title}</title>
      </Helmet>
      {renderHeader()}
      <main style={{ marginTop: '50px' }}>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme='light'
        />
        {children}
      </main>
    </div>
  )
}

Layout.defaultProps = {
  title: 'Event Registration System (ERS)',
  description: 'Event Registration System (ERS)',
  author: 'Event Registration System (ERS)',
}

export default Layout
